import React, { useEffect, useState } from "react"
// import Sticky from 'react-sticky-el'
import { useIdentityContext } from 'react-netlify-identity-gotrue'
import { IdentityContextProvider } from 'react-netlify-identity'
import { Helmet } from "react-helmet"
import LayoutNoStripe from "../../components/layoutNoStripe"
import SEO from "../../components/seo"
import {
  Section
} from "../../styledComponents/section"

const MyAccountForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const identity = useIdentityContext();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await identity.requestPasswordRecovery(email);
      setMessage('Check your email for a password reset link.');
    } catch (error) {
      setMessage('Error sending reset email. Please try again.');
    }
  };

  return (
    <>
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />
      </Helmet>
      <LayoutNoStripe displayNavBorder hideNewsletterSignUp>
        <IdentityContextProvider url="https://www.karinagrant.co.uk/">
          <Section>
            <h2>Forgot Password</h2>
            <form onSubmit={handleForgotPassword}>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit">Send Reset Link</button>
            </form>
            {message && <p>{message}</p>}
          </Section>
        </IdentityContextProvider>
      </LayoutNoStripe>
    </>
  )
}

export default MyAccountForgotPassword

